@use "../../../variables.scss" as variables;

// .customer-tab {
.nav-nav-tabs {
    border-bottom: 2px solid #ddd !important;
}

.nav-nav-tabs > li {
    margin-bottom: -1px !important; // Remove the default bottom margin
}

.nav-nav-tabs > li > a {
    border: none !important;
    color: #9d9d9f !important; // Default color for unselected tabs
    background: transparent !important;
    position: relative !important;
}

.nav-nav-tabs > li.active > a,
.nav-nav-tabs > li > a:hover {
    border: none !important;
    color: variables.$primary-argentinian-blue !important;
    background: transparent !important;
}

.nav-nav-tabs > li > a::after {
    content: "" !important;
    position: absolute !important;
    width: 100% !important;
    height: 2px !important; // Height of the line below the tab
    left: 0 !important;
    bottom: -2px !important; // Adjust the position to hide the default border
    background-color: variables.$primary-argentinian-blue !important; // Color of the line below the tab
    transform: scaleX(0) !important; // Initial scale to hide the line
    transition: transform 0.3s ease !important;
}

.nav-nav-tabs > li.active > a::after,
.nav-nav-tabs > li:hover > a::after {
    transform: scaleX(
        1
    ) !important; // Show the line on hover and for the selected tab
}

// Ensure that the line is also shown when the tab is focused (e.g., via keyboard navigation)
.nav-nav-tabs > li.active > a:focus::after,
.nav-nav-tabs > li:focus > a::after {
    transform: scaleX(1) !important;
}

/** Nav Pills **/

.nav-pills {
    // width: fit-content;
    // background-color: variables.$secondary-color-blue-gray-50 !important;
    border-radius: 0%;
    color: variables.$primary-color-blue-gray !important; // Color for selected tab
    .nav-link.active {
        color: white !important;
    }
}

.nav-pills > li {
    margin-bottom: -1px; // Remove the default bottom margin
}

.nav-pills > li > a {
    border: none;
    color: #000; // Default color for unselected tabs
    background: #F5F5F5;
    position: relative;
}

.nav-pills > li.active > a:active,
.nav-pills > li > a:hover,
.nav-pills > li > a:focus {
    border: none;
    color: variables.$secondary-night-tint-400 !important;
    font-weight: variables.$font-weight-semibold;
}

.nav-pills.nav-link {
    border-radius: 0%;
}
.nav-pills .nav-link.active {
    background-color: variables.$primary-night !important; 
    color: variables.$white !important; 
    border-radius: 0%;
    font-weight: variables.$font-weight-semibold;
}
