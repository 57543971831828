@use "../../variables.scss" as variables;

.card-image-container {
    position: relative;
}

.tick-icon {
    position: absolute;
    top: 10px; /* adjust as needed */
    right: 10px; /* adjust as needed */
}

.carding:hover {
    cursor: pointer;
}

.carding:active {
    border: 2px solid variables.$primary-slate-blue; /* adjust as needed */
}

.carding:focus {
    border: 2px solid variables.$primary-slate-blue; /* adjust as needed */
}

.selected-card {
    border: 2px solid variables.$primary-slate-blue !important;
}

.disabled {
    filter: grayscale(1);
}

.checkout-model {
    // Default styles for mobile devices
    width: 100% !important;

    // Styles for tablets and small desktops
    @media (min-width: 768px) {
        width: 300px !important;
    }

    // Styles for large desktops
    @media (min-width: 1024) {
        width: 800px !important;
    }
}
