@use '../../../variables.scss' as variables;

.overview-card {
	background: white;
	border-radius: 10px;
	padding: 20px;
	height: 100%;

	.overview-card-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 16px;

		.card-title {
		color: #666;
		font-size: 14px;
		margin: 0;
		}

		.card-icon {
		opacity: 0.5;
		}
	}

	.card-content {
		.main-value {
		font-size: 24px;
		font-weight: 600;
		margin: 0;
		color: #333;
		}

		.sub-value {
		font-size: 12px;
		color: #888;
		margin: 8px 0 0;
		}
	}
}
