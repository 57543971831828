@use "../../../variables.scss" as variables;

.apy-component {
  color: variables.$table-apy-color;
}

.pool-label {
  background-color: variables.$trafefi-primary-color;
  border-radius: 4px;
  width: 159px; // Set a fixed width
  height: 32px;
  display: inline-block; // Ensure the element respects the width
  text-align: center; // Center the text inside the label

  @media (max-width: 600px) {
    // Change this to the breakpoint you want
    background-color: transparent;
    text-align: right;
    width: 100px;
  }
}

.risk-rating-component {
  gap: 0.5em;
}

.risk-rating-progress {
  flex-grow: 0;
  margin-right: 0.5em;
  height: 4px;
  width: 100px;
}

@media (max-width: 600px) {
  // Change this to the breakpoint you want
  .risk-rating-component {
    text-align: right;
  }
  .risk-rating-progress {
    display: none;
    text-align: right;
  }
  .risk-value {
    text-align: right;
  }
}

.risk-rating-progress .progress-bar {
  background-color: variables.$primary-night;
}

// .new-pool-button,
// .start-pool-button {
//     background-color: variables.$trafefi-primary-color !important;
//     color: variables.$primary-night !important;
//     border-radius: 5px !important;
//     border: none !important;

//     &:hover {
//         background-color: variables.$secondary-night-tint-200 !important;
//         color: variables.$primary-night !important;
//     }

//     &:focus {
//         background-color: variables.$secondary-night-tint-200 !important;
//         color: variables.$primary-night !important;
//     }
// }

.link-button {
  color: variables.$primary-color-blue-gray;
  font-family: variables.$font-family-public-sans;
  font-size: 1 em;
  font-style: normal;
  font-weight: 700;
  line-height: 21px /* 150% */;
  letter-spacing: 0.21px;
  cursor: pointer;
}
