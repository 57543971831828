@use "../../variables.scss" as variables;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: variables.$font-family-public-sans;
}

.table-responsive {
  background-color: variables.$white;
  font-family: variables.$font-family-public-sans;
  color: variables.$primary-night;
  height: 330px;
  border-radius: 12px;
  background: variables.$white;
}

.table-header {
  background-color: transparent;
  color: variables.$primary-color-dark-blue;
  font-family: variables.$font-family-public-sans;
  font-size: 0.9em;
  font-weight: variables.$font-weight-medium;
}

.table tbody tr {
  background-color: transparent;
  font-size: 0.8em;
  line-height: 3;
}

.table th {
  border-top: none;
  background-color: transparent;
  border-bottom: 1px solid #e5e8eb;
  line-height: 3;
}

.table tr {
  border-top: none;
  background-color: transparent;
}

.page-navigator {
  background-color: variables.$primary-night;
  color: variables.$white;
}

.previous-page,
.next-page {
  background-color: transparent;
  border-color: none;
  border: 0cap;
  color: variables.$primary-color-dark-gray-50 !important;
  font-weight: variables.$font-weight-semibold;
  font-family: variables.$font-family-public-sans;
}

.page-item.disabled .previous-page,
.page-item.disabled .next-page {
  background-color: #F5F5F5;
  border: none;
  .page-link {
    color: variables.$primary-night;
    font-family: variables.$font-family-public-sans;
  }
}

.page-item.active,
.page-item.active:focus {
  background-color: variables.$primary-night;
  border: none;
  color: variables.$white !important;
  .page-link {
    border-color: transparent;
    color: variables.$white !important;
    background-color: variables.$primary-night !important;
    font-family: variables.$font-family-public-sans;
  }
}

.page-number {
  background-color: variables.$secondary-night-tint-300 !important;
  color: variables.$primary-night !important;
  font-family: variables.$font-family-public-sans;
  border: none;
}

.page-number:hover,
.page-number:focus,
.page-number.active {
  background-color: variables.$primary-night !important;
  color: variables.$white;
  font-family: variables.$font-family-public-sans;
}

.custom-control-input ::before {
  background-color: variables.$secondary-slate-blue-tint-100;
  width: 25px;
  height: 25px;
}

.table-row-border {
  border-bottom: 1px solid #e5e8eb;
}

@media screen and (max-width: 600px) {
  table {
    border: 0;
  }

  .table-responsive,
  .sort-wrapper {
    display: flex;
    gap: 20px;
  }

  table caption {
    font-size: 1.3em;
  }

  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  table tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: 0.625em;
  }

  table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: 0.8em;
    text-align: right;
  }

  table td::before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }

  table td:last-child {
    border-bottom: 0;
  }
}