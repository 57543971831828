@use '../../../variables.scss' as variables;

.first-row {
  border-top: 1px solid variables.$border-color;
}

.border-bottom {
  border-bottom: 1px solid variables.$border-color;
}

.title-text {
  font-family: variables.$font-family-inter;
  font-weight: variables.$font-weight-normal;
  font-size: 0.875rem;
  color: variables.$muted-text-color;
}

.value-text {
  font-family: variables.$font-family-inter;
  font-weight: variables.$font-weight-medium;
  font-size: 0.875rem;
  color: variables.$primary-night;
}

.status-cell {
  background-color: variables.$trafefi-primary-color;
  border-radius: 4px;
  width: 159px; // Set a fixed width
  height: 32px;
  display: inline-block;
  text-align: center;

  @media (max-width: 600px) {
    background-color: transparent;
    text-align: right;
    width: 100px;
  }
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media (max-width: 600px) {
    display: inline-block;
    background-color: transparent;
    text-align: right;
    width: 80px;
  }
}

.scrollable-container {
  overflow-x: auto;
  white-space: nowrap;
  width: 100%;
  max-height: 400px; // Set a maximum height if needed
  border: 1px solid #ccc; // Optional: Add a border to make the scrollable area more visible
}

.brand-item {
  text-align: center;
  flex: 0 0 30%; /* Adjust the flex-basis to ensure three items per row */
}

.brand-image {
  width: 50%;
  height: auto;
  border-radius: 50%;
}

.brand-item {
  .brand-image {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: cover;
  }

  .brand-initials {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    background-color: rgb(229, 229, 229);
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    font-size: 1.05rem; // Adjust font size accordingly
    font-weight: 500;
  }
}