.underline-tabs-container {
  padding-top: 50px;
  margin-top: 0.5rem;
}

.underline-tabs {
  display: flex;
  gap: 32px;
  border-bottom: 1px solid #e0e0e0;
}

.tab-button {
  background: none;
  border: none;
  padding: 12px 4px;
  position: relative;
  color: #666;
  cursor: pointer;
  transition: color 0.3s;
  @media (max-width: 600px) {
    font-size: 3vw;
  }
}

.tab-button::after {
  content: '';
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: transparent;
  transition: background-color 0.3s;
}

.tab-button:hover {
  color: #1a73e8;
}

.tab-button.active {
  color: #1a73e8;
}

.tab-button.active::after {
  background-color: #1a73e8;
}
