/*
* Typography
*/

@use "../../variables.scss" as variables;

.typography--variant-display1,
.typography--variant-display2,
.typography--variant-display3,
.typography--variant-display4 {
    font-family: variables.$font-family-display;
}

.typography--variant-h1,
.typography--variant-h2,
.typography--variant-h3,
.typography--variant-h4,
.typography--variant-h5,
.typography--variant-h6 {
    font-family: variables.$font-family-public-sans;
}

.typography--variant-subtitle,
.typography--variant-buttonText,
.typography--variant-labels,
.typography--variant-hintText,
.typography--variant-body1,
.typography--variant-body2,
.typography--variant-link {
    font-family: variables.$font-family-public-sans;
}

.typography--variant-display1 {
    font-size: 2.2em;
    font-weight: variables.$font-weight-normal;
    // line-height: 2em;
}

.typography--variant-display2 {
    font-size: 2.25em;
    font-weight: variables.$font-weight-normal;
    line-height: 47px;
}

.typography--variant-display3 {
    font-size: 1.75em;
    font-weight: variables.$font-weight-normal;
    line-height: 36px;
}

.typography--variant-display4 {
    font-size: 1.25em;
    font-weight: variables.$font-weight-normal;
    line-height: 28px;
}

.typography--variant-h1 {
    font-size: 2em;
    font-weight: variables.$font-weight-bold;
}

.typography--variant-h2 {
    font-size: 1.5em;
    font-weight: variables.$font-weight-bold;
}

.typography--variant-h3 {
    font-size: 1.17em;
    font-weight: variables.$font-weight-bold;
    line-height: 26px;
}

.typography--variant-h4 {
    font-size: 1em;
    font-weight: variables.$font-weight-bold;
    line-height: 1.3125em;
    font-display: swap;
}

.typography--variant-h5 {
    font-size: 0.83em;
    font-weight: variables.$font-weight-bold;
    line-height: 1.3125em;
}

.typography--variant-h6 {
    font-size: 0.67em;
    font-weight: variables.$font-weight-bold;
    line-height: 1.3125em;
}

.typography--variant-subtitle {
    font-size: 1em;
    font-weight: variables.$font-weight-lighter;
    line-height: 21px;
    color: variables.$secondary-night-tint-500;
}

.typography--variant-buttonText {
    font-size: 1em;
    font-weight: variables.$font-weight-semibold;
    line-height: 21px;
}

.typography--variant-labels {
    font-size: 0.875em;
    font-weight: variables.$font-weight-normal;
    line-height: 18px;
    color: variables.$primary-night;
}

.typography--variant-hintText {
    font-size: 0.875em;
    font-weight: variables.$font-weight-normal;
    line-height: 18px;
}

.typography--variant-body1 {
    font-size: 0.875em;
    font-weight: variables.$font-weight-normal;
    color: variables.$primary-color-dark-gray;
}

.typography--variant-body2 {
    font-size: 0.875em;
    font-weight: variables.$font-weight-normal;
    color: variables.$primary-color-blue-gray;
}

.typography--variant-link {
    font-size: 1em;
    font-weight: variables.$font-weight-normal;
    line-height: 22px;
    color: variables.$information;
    text-decoration: underline;
}
